import React from 'react'
import styled, { keyframes } from 'styled-components'
import Progress from 'components/progress'
import Timeline from 'sections/about/parts/Timeline'

class TabsPart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "skills"
        }
    }

    render() {
        const TabContainer = styled.div`
            min-height: 400px;
            margin-top: 20px;
            @media (max-width:767px) {
                margin-top: 50px;
                padding: 0 20px;
            }
        `
        const TabSelectors = styled.div`
            display: flex;
        `
        
        const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `
        const TabSelector = styled.button`
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            margin: 0 10px 0 0;
            border-bottom: 2px solid #fff;
            transition: .5s;
            &:hover, &.active {
                animation: ${ColorAnimation} 10s infinite alternate;
            }
            &:focus {
                outline: none;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        `

        const Tabs = styled.div`
            margin-top: 20px;
        `

        const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `

        const Tab = styled.div`
            display: none;
            animation: ${Fade} 1s forwards;
        `

        return(
            <TabContainer>
                <TabSelectors>
                    <TabSelector className={this.state.tab === "skills" ? "active" : ""} onClick={() => this.setState({tab: "skills"})}>
                        Skills
                    </TabSelector>
                    <TabSelector className={this.state.tab === "experience" ? "active" : ""} onClick={() => this.setState({tab: "experience"})}>
                        Experience
                    </TabSelector>
                    <TabSelector className={this.state.tab === "education" ? "active" : ""} onClick={() => this.setState({tab: "education"})}>
                        Education
                    </TabSelector>
                </TabSelectors>
                <Tabs>
                    <Tab style={{
                        display: this.state.tab === "skills" ? "block" : "none"
                    }}>
                        <Progress value={90} text="Javascript" />
                        <Progress value={60} text="React" />
                        <Progress value={90} text="Angular" />
                        <Progress value={30} text="MongoDB" />
                        <Progress value={80} text="PHP" />
                        <Progress value={80} text="MySQL" />
                        <Progress value={95} text="HTML" />
                        <Progress value={95} text="CSS" />
                        <Progress value={70} text="wordpress" />
                    </Tab>
                </Tabs>
                <Tabs>
                    <Tab style={{
                            display: this.state.tab === "experience" ? "block" : "none"
                        }}>
                        <Timeline data={{
                            "2021 -" :  {
                                title: "SYSTEM ANALYST",
                                institution: "CYBAGE SOFTWARE",
                                description: `Deliver engaging user experience through optimization of images, code, and cross-browser
                                compatibility, increasing Time on Page by +60 seconds for hospitality product.
                                • Expand features, refine code, and improve processes, producing smoother operations and
                                enhancing user engagement
                                • Designed dynamic and browser compatible pages using HTML5, CSS3, Javascript, Angular,
                                and web component
                                • Built single page applications (SPA), responsive web design, UI using HTML5 grid layouts,
                                CSS3 media queries where its an expression and can be used to either true or false.
                                • Ensure efficient web development by supporting designers and app developers while
                                resolving website performance issues
                                • Led a team of developers in creating a new software application.`
                            },
                                "2016 - 2021" :  {
                                    title: "HEAD – FRONT-END DEVELOPMENT",
                                    institution: "ATLAS SOFTWEB PVT. LTD",
                                    description: "Working as a project manager in Atlas softweb my key responsibility are to comunicate with client, understanding client requirement, providing an estimate of the project. Ensure Maximum utilization of team ability, Coordinating staff and internal resources, Managing project progress and adapt work as required, Ensuring projects meet deadlines, Managing relationships with clients and stakeholders."
                                },
                                "2011 - 2015" : {
                                    title: "Front-end Developer",
                                    institution: "ATLAS INFOTECH",
                                    description: "Working as responsive web designer, my key responsibility are to develop HTML static pages from PSD, or AI file project by graphic designer or by client. Understading layout structure from mockup, integrating HTML into wordpress and developer wordpress dynamic functionality as well."
                                },
                            }
                        }
                    />
                    </Tab>
                    <Tab style={{
                            display: this.state.tab === "education" ? "block" : "none"
                        }}>
                        <Timeline data={{
                                "2009 - 2012" :  {
                                    title: "Bachelor Degree",
                                    institution: "Gujarat Univercity",
                                    description: "complered Bachelor degree in commerce from Gujrat Univercity in year 2012. I have done Bachelor in commerce subject."
                                },
                                "2007 - 2009" : {
                                    title: "High school",
                                    institution: "Gujarat Board",
                                    description: "Have completed my 12th from gujarat board in year 2009."
                                }
                            }
                        }
                    />
                    </Tab>
                </Tabs>
            </TabContainer>
        )
    }
}

export default TabsPart