import React from 'react'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import HeroPersonal from '../sections/hero/HeroPersonal'
import AboutPersonal from '../sections/about/AboutPersonal.js'
import ServicesThree from '../sections/services/ServicesThree.js'
import PortfolioOne from '../sections/portfolio/PortfolioOne.js'
import TestimonialsTwo from '../sections/testimonials/TestimonialsTwo.js'
import ClientsOne from '../sections/clients/ClientsOne.js'
import ContactPersonal from '../sections/contact/ContactPersonal.js'

class Index extends React.Component {
 
  render() {

    const { site } = this.props.data
    
    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
          <script>
            {`
            function initFingerprintJS() {
              FingerprintJS.load({token: 'PV0T1Cb4ZNEWoI3ejw3X'})
                .then(fp => fp.get())
                .then(result => console.log(result.visitorId));
            }`}
          </script>
          <script
            async
            src="https://cdn.jsdelivr.net/npm/@fingerprintjs/fingerprintjs-pro@3/dist/fp.min.js"
            onload="initFingerprintJS()"
          ></script>
        </Helmet>
        <Layout
          isHome={true}
          sections={['home', 'about', 'services', 'portfolio', 'achievements', 'skills', 'contact']}
        >
          <HeroPersonal />
          <AboutPersonal />
          <ServicesThree />
          <PortfolioOne />
          <TestimonialsTwo />
          <ClientsOne />
          <ContactPersonal />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`